import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import sal from 'sal.js';
import { Ticket, ChevronRight } from 'akar-icons';

const IndexPage = () => {
    const [openTickets, setOpenTickets] = useState([]);
    const [closedTickets, setClosedTickets] = useState([]);
    const [pendingTickets, setPendingTickets] = useState([]);
    const [showTicketStatus, setShowTicketStatus] = useState('open');
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });

    const newTicket = (e) => {
        if (e) {
            e.preventDefault();

            navigate('/new-ticket');
        }
    };

    useEffect(() => {
        CustomFetch(
            'tickets/list',
            'POST',
            {
                where: [
                    {
                        id: 'status_id',
                        value: 1,
                    },
                ],
            },
            function (result) {
                setOpenTickets(result);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );

        CustomFetch(
            'tickets/list',
            'POST',
            {
                where: [
                    {
                        id: 'status_id',
                        value: 2,
                    },
                ],
            },
            function (result) {
                setClosedTickets(result);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );

        CustomFetch(
            'tickets/list',
            'POST',
            {
                where: [
                    {
                        id: 'status_id',
                        value: 3,
                    },
                ],
            },
            function (result) {
                setPendingTickets(result);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );
    }, []);

    useEffect(() => {
        setTimeout(() => {
            sal();
        }, 100);
    }, [openTickets]);

    return (
        <Layout title="Dashboard">
            <Seo title="Dashboard" description="Dawn Projects Ticket System" />
            <CustomMessage data={messageSetting} />
            <div className="wrap">
                <div
                    className="dashmenu"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                >
                    <button
                        className="dashmenu__item newticket"
                        onClick={newTicket}
                    >
                        New Ticket <Ticket size={20} />
                    </button>
                    <button
                        className="dashmenu__item"
                        onClick={() => {
                            setShowTicketStatus('open');
                            setTimeout(() => {
                                sal();
                            }, 100);
                        }}
                    >
                        Open Tickets <span>{openTickets.length}</span>
                    </button>
                    <button
                        className="dashmenu__item"
                        onClick={() => {
                            setShowTicketStatus('closed');
                            setTimeout(() => {
                                sal();
                            }, 100);
                        }}
                    >
                        Closed Tickets <span>{closedTickets.length}</span>
                    </button>
                </div>
                {showTicketStatus === 'open' ? (
                    <>
                        <div
                            className="breaktitle"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <h2>Open Tickets</h2>
                        </div>
                        <div className="tickets">
                            {openTickets.length > 0 ? (
                                openTickets.map((a) => (
                                    <Link
                                        to="/ticket"
                                        data-sal="slide-down"
                                        data-sal-duration="500"
                                        data-sal-easing="ease-in-out-quart"
                                        key={`tickets-${a.id}`}
                                        state={{ ticket_id: a.id }}
                                    >
                                        <span className="ticket">
                                            <span className="ticket__top">
                                                <h3>Ticket No. {a.id}</h3>
                                                <small>
                                                    {a.status.label}{' '}
                                                    <Ticket size={20} />
                                                </small>
                                                <p>{a.description}</p>
                                            </span>
                                            <span className="ticket__bot">
                                                <em>View Ticket</em>
                                                <ChevronRight size={24} />
                                            </span>
                                        </span>
                                    </Link>
                                ))
                            ) : (
                                <div
                                    className="noticket"
                                    data-sal="slide-down"
                                    data-sal-duration="500"
                                    data-sal-easing="ease-in-out-quart"
                                >
                                    <span>
                                        You currently have no open tickets. To
                                        open one, please click "New Ticket".
                                    </span>
                                </div>
                            )}
                        </div>
                        <div
                            className="breaktitle"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <h2>Pending Tickets</h2>
                        </div>
                        <div className="tickets">
                            {pendingTickets.length > 0 ? (
                                pendingTickets.map((a) => (
                                    <Link
                                        to="/ticket"
                                        data-sal="slide-down"
                                        data-sal-duration="500"
                                        data-sal-easing="ease-in-out-quart"
                                        key={`tickets-${a.id}`}
                                        state={{ ticket_id: a.id }}
                                    >
                                        <span className="ticket">
                                            <span className="ticket__top">
                                                <h3>Ticket No. {a.id}</h3>
                                                <small className="pending">
                                                    {a.status.label}{' '}
                                                    <Ticket size={20} />
                                                </small>
                                                <p>{a.description}</p>
                                            </span>
                                            <span className="ticket__bot">
                                                <em>View Ticket</em>
                                                <ChevronRight size={24} />
                                            </span>
                                        </span>
                                    </Link>
                                ))
                            ) : (
                                <div
                                    className="noticket"
                                    data-sal="slide-down"
                                    data-sal-duration="500"
                                    data-sal-easing="ease-in-out-quart"
                                >
                                    <span>
                                        You currently have no pending tickets.
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                ) : null}

                {showTicketStatus === 'closed' ? (
                    <>
                        <div
                            className="breaktitle"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <h2>Closed Tickets</h2>
                        </div>
                        <div className="tickets">
                            {closedTickets.length > 0 ? (
                                closedTickets.map((a) => (
                                    <Link
                                        to="/ticket"
                                        data-sal="slide-down"
                                        data-sal-duration="500"
                                        data-sal-easing="ease-in-out-quart"
                                        key={`tickets-${a.id}`}
                                        state={{ ticket_id: a.id }}
                                    >
                                        <span className="ticket">
                                            <span className="ticket__top">
                                                <h3>Ticket No. {a.id}</h3>
                                                <small className="recent">
                                                    {a.status.label}{' '}
                                                    <Ticket size={20} />
                                                </small>
                                                <p>{a.description}</p>
                                            </span>
                                            <span className="ticket__bot">
                                                <em>View Ticket</em>
                                                <ChevronRight size={24} />
                                            </span>
                                        </span>
                                    </Link>
                                ))
                            ) : (
                                <div
                                    className="noticket"
                                    data-sal="slide-down"
                                    data-sal-duration="500"
                                    data-sal-easing="ease-in-out-quart"
                                >
                                    <span>
                                        You currently have no closed tickets. To
                                        open one, please click "New Ticket".
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                ) : null}
            </div>
        </Layout>
    );
};

export default IndexPage;
